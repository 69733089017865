/**
 * token刷新方法，
 * 1、当第一次出现401的时候，立即从api里请求token，并将“异步体”保存为 refreshPromise
 * 2、后续的请求相当于都是给 apiRefreshToken 添加then
 * 3、当token刷新完毕，refreshPromise 应当清空为下次做准备
 */
import request from "./request.js";
 class RefreshToken {
  constructor() {
      this.refreshPromise = undefined;
  }
  apiRefreshToken() {
    console.log(1)
      if (!this.refreshPromise) {
        console.log(2)
          this.refreshPromise = new Promise((resolve, reject) => {
            console.log(3)
            request({url: 'member/login/refreshToken', method: 'post'}).then(res => {
              console.log(res)
              localStorage.setItem('token', res.data.token.access_token)
              resolve();
            }).catch(() => {
              console.log('操作失败')
              reject('失败')
            })
          });
      }
      return this.refreshPromise;
  }
  refreshIsLoading() {
      return Boolean(this.refreshPromise);
  }
}
export default new RefreshToken();
