import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'runapp',
    component: () => import('@/views/runapp/index.vue'),
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/index.vue')
      },
      {
        path: '/account',
        name: 'account',
        component: () => import('@/views/account/index.vue'),
        children: [
          {
            path: '/account-control',
            name: 'accountControl',
            component: () => import('@/views/account/accountControl.vue')
          },
          {
            path: '/sort-search',
            name: 'sortSearch',
            component: () => import('@/views/account/sortSearch.vue')
          },
          {
            path: '/extension-assistant',
            name: 'extensionAssistant',
            component: () => import('@/views/account/extensionAssistant.vue')
          },
        ]
      },
      {
        path: '/ai',
        name: 'AI',
        component: () => import('@/views/aiclip/index.vue'),
        children: [
          {
            path: '/material-manage',
            name: 'materialManage',
            component: () => import('@/views/aiclip/materialManage.vue')
          },
        ]
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
