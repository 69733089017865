//引入axios实例
import request from "./request.js";
//登录
export const login = (data)=>request({url: 'member/login/login', data, method: 'post'})
//关键词拓词
export const keywords_expand = (data)=>request({url: 'seo/keywords/expand', data, method: 'post'})
//获取关键词分组列表
export const keywords_group_get_list = (data)=>request({url: 'seo/keywords_group/get_list', data, method: 'post'})
//获取关键词分组列表下拉选择用
export const keywords_group_get_select = (data)=>request({url: 'seo/keywords_group/get_select', data, method: 'post'})
//保存关键字
export const keywords_add = (data)=>request({url: 'seo/keywords/add', data, method: 'post'})
//根据关键词组id获取关键词列表
export const keywords_get_list = (data)=>request({url: 'seo/keywords/get_list', data, method: 'post'})
//添加词组
export const keywords_group_add = (data)=>request({url: 'seo/keywords_group/add', data, method: 'post'})
//编辑词组
export const keywords_group_edit = (data)=>request({url: 'seo/keywords_group/edit', data, method: 'post'})
//删除词组
export const keywords_group_del = (data)=>request({url: 'seo/keywords_group/del', data, method: 'post'})
//删除词组
export const keywords_del = (data)=>request({url: 'seo/keywords/del', data, method: 'post'})


// 素材分组列表
export const material_group_get_list = (data)=>request({url: 'seo/material_group/get_list', data, method: 'post'})
// 素材分组列表--下拉选择用
export const material_group_get_select = (data)=>request({url: 'seo/material_group/get_select', data, method: 'post'})
// 根据分组id获取素材列表
export const material_get_list = (data)=>request({url: 'seo/material/get_list', data, method: 'post'})
// 获取阿里云参数
export const getOssSts = (data)=>request({url: 'seo/material/getOssSts', data, method: 'post'})
// 保存素材
export const material_add = (data)=>request({url: 'seo/material/add', data, method: 'post'})
// 素材编辑分组
export const material_group_edit = (data)=>request({url: 'seo/material_group/edit', data, method: 'post'})
// 素材添加分组
export const material_group_add = (data)=>request({url: 'seo/material_group/add', data, method: 'post'})
// 素材删除分组
export const material_group_del = (data)=>request({url: 'seo/material_group/del', data, method: 'post'})
// 素材删除
export const material_del = (data)=>request({url: 'seo/material/del', data, method: 'post'})


// 获取授权地址
export const account_getAuthUrl = (data)=>request({url: 'douyin/account/getAuthUrl', data, method: 'post'})
// 根据code获取信息
export const account_getInfoByCode = (data)=>request({url: 'douyin/account/getInfoByCode', data, method: 'post'})
// 根据code获取信息
export const account_get_list = (data)=>request({url: 'douyin/account/get_list', data, method: 'post'})

