import axios from "axios";
import tokenServer from "./tokenServer";
// // 引入element-ui
import elementUi from 'element-ui'
// 引入路由，跳转登录页面
import router from '../router/index.js'
import $config from '@/config'
//引入进度条
import Nprogress from "nprogress";
//引入进度条样式
import 'nprogress/nprogress.css'
// 创建axios实例
const service = axios.create({
    baseURL: $config.baseUrl,
    timeout: 5000,
    data: {}
});

// 请求头拦截器，组合token
service.interceptors.request.use(
    (config) => {
        Nprogress.start()
        config.headers["Content-Type"] = "application/json;charset=UTF-8";
        config.headers["Authorization"] = localStorage.getItem('token'); // 这里在本地缓存拿token
        return config;
    },
    (error) => Promise.reject(error)
);

// 请求结果拦截器
service.interceptors.response.use(
    async (response) => {
        Nprogress.done()
        const { code, msg } = response.data;
        if (code == "1") {
            return Promise.resolve(response.data);
        } else if (code == 401) {
          if (msg == '操作失败') {
            return Promise.reject();
          }
            try {
                await tokenServer.apiRefreshToken()
                return Promise.resolve(service(response.config._original));
            } catch {
              tokenServer.refreshPromise = undefined
               console.log('9999999999', elementUi)
               elementUi.Message({
                   message: '登录已失效，请重新登录',
                   type: 'error'
               })
               router.replace({
                  path: "/login",
              })
              return;
            }
        } else {
            elementUi.Message({
                message: msg,
                type: 'error'
            })
            return Promise.reject(response.data);
        }
    },
    (error) => {
        elementUi.Message({
            message: "服务器链接失败，请重试！",
            type: 'error'
        })
        return Promise.reject(error.response);
    }
);

// 如果正在刷新token时，需等待token刷新完毕再触发接口请求(暂时挂起)
// 拓展入参_original，保留原始请求的入参，目的是为了拦击里再次请求接口所需参数
const request = async (params) => {
    let refreshIsLoading = tokenServer.refreshIsLoading();
    if (refreshIsLoading) {
        await tokenServer.apiRefreshToken();
    }
    params._original = JSON.parse(JSON.stringify(params));
    service.data = params.data;
    return service(params);
};

export default request;





























// import axios from "axios";
// //引入进度条
// import Nprogress from "nprogress";
// //引入进度条样式
// import 'nprogress/nprogress.css'
// // 引入路由，跳转登录页面
// import router from '../router/index.js'
// // 引入element-ui
// import elementUi from 'element-ui'
// // 引入配置
// import $config from '@/config'
// // 刷新token使用
// const refreshToken = (data)=>service.post('user/login/refreshToken', data)
// console.log(refreshToken)
// //利用axios对象的方法create，去创建一个axios实例
// const service = axios.create({
//   //基础路径
//   baseURL: $config.baseUrl,
//   //请求超时时间   （10s内没响应请求超时）
//   timeout:10000,
//   data: {},
// 	headers: {
// 		'Content-Type': 'application/json;charset=UTF-8'
// 	}
// })
// var CancelToken;
// var source;
// const sourceArr = []; // 记录请求，当token过期，取消其他请求
// //请求拦截器  
// //axios实例的拦截器的request请求使用配置对象，返回配置对象
// service.interceptors.request.use((config)=>{
// 	CancelToken = axios.CancelToken;
// 	source = CancelToken.source();
// 	sourceArr.push(source)
// 	let loginData = JSON.parse(localStorage.getItem('loginData'))
// 	let expires_in = loginData.token.expires_in
// 	let refresh_expires_in = loginData.token.refresh_expires_in
// 	console.log(expires_in, refresh_expires_in)
// 	config.cancelToken = source.token; // 全局添加cancelToken
//   //进度条开始
//   Nprogress.start()
// 	config.headers.Authorization = localStorage.getItem('token')
//   //请求成功的返回配置对象
//   return config
// },(error)=>{
//   //请求失败的返回，后面的then或者catch回调随便写不写
//   return Promise.reject(error)
// })

// //响应拦截器
// service.interceptors.response.use((response)=>{
//   //进度条结束
//   Nprogress.done()
// 	if (response.data.code == 1) {
// 		//响应成功的返回
// 		return response.data
// 	} 
// 	if (response.data.code == 401) {
// 		console.log(sourceArr)
// 		sourceArr.map(item => {
// 			item.cancel();
// 		})
// 		// source.cancel();
// 		router.push('/login')
// 		elementUi.Message({
// 			message: response.data.msg,
// 			type: 'error'
// 		})
// 		// eslint-disable-next-line no-const-assign
// 		sourceArr = [];
// 		return Promise.reject(response.data.msg)
// 	} else {
// 		elementUi.Message({
// 			message: response.data.msg,
// 			type: 'error'
// 		})
// 		return Promise.reject(response.data.msg)
// 	}
// },(error)=>{
//   Nprogress.done()
//   //响应失败的返回
//   return Promise.reject(error)
// })

// //导出axios实例
// export default service

